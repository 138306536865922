import { SOCIAL_MEDIA_TYPE } from "src/main/contants";
import {
  AppConfiguration,
  CountryCurrency,
  CountryEnvKey,
  CountryPhoneCode,
  DeployCountry,
  DeployEnv,
  Language,
} from "src/main/types";

const MAIN_DOMAINS: Record<DeployCountry, string> = {
  [DeployCountry.TH]: "ilovevip.com",
  [DeployCountry.PH]: "ilovevip.org",
};

// APPLICATION CONFIGURATION
const appConfig: AppConfiguration<DeployCountry, Language, DeployEnv> = {
  domains: [
    // TH DOMAINS
    // TH main domains
    {
      domain: MAIN_DOMAINS.th,
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth1.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth2.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    {
      domain: "ilvth3.com",
      country: DeployCountry.TH,
      isMain: true,
      env: DeployEnv.Production,
    },
    // TH brand domains
    {
      domain: "wow69vip.com",
      brand: "wow69",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "xo268vip.com",
      brand: "xo268",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow99vip.com",
      brand: "wow99",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "xo666vip.com",
      brand: "xo666",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "dee99vip.com",
      brand: "dee99",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "big666vip.com",
      brand: "big666",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "boom88vip.com",
      brand: "boom88",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "good777vip.com",
      brand: "good777",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "moo555vip.com",
      brand: "moo555",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wint9vip.com",
      brand: "wint8",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "xogovip.com",
      brand: "xogo",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "chaiyo268vip.com",
      brand: "chaiyo268",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "kbk99vip.com",
      brand: "kbk99",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow268vip.com",
      brand: "wow268",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "kbk99vipp.com",
      brand: "kbk99",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "moo555vipp.com",
      brand: "moo555",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "chaiyo268vipp.com",
      brand: "chaiyo268",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "wow268vipp.com",
      brand: "wow268",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "big666vipp.com",
      brand: "big666",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "go989vvip.com",
      brand: "go989",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },
    {
      domain: "sss268vip.com",
      brand: "sss268",
      country: DeployCountry.TH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.th,
    },

    // PH DOMAINS
    // PH main domains
    {
      domain: MAIN_DOMAINS.ph,
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilovevipph.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph1.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph2.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    {
      domain: "ilvph3.com",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      isMain: true,
    },
    // PH brand domains
    {
      domain: "winhqvip.com",
      brand: "winhq",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "ezjilivip.com",
      brand: "ezjili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "xojilivip.com",
      brand: "xojili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "wowjilivip.com",
      brand: "wowjili",
      country: DeployCountry.PH,

      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "winhqvipp.com",
      brand: "winhq",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "ezjilivipp.com",
      brand: "ezjili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "xojilivipp.com",
      brand: "xojili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "wowjilivipp.com",
      brand: "wowjili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "ezjiliilvph.com",
      brand: "ezjili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "xojiliilvph.com",
      brand: "xojili",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    {
      domain: "winhqilvph.com",
      brand: "winhq",
      country: DeployCountry.PH,
      env: DeployEnv.Production,
      mainUrl: MAIN_DOMAINS.ph,
    },
    // STAGING DOMAINS
    {
      domain: "staging-member.ilovevip.com",
      country: DeployCountry.TH,
      env: DeployEnv.Staging,
      isMain: true,
      // Override country configuration
      countryConfig: {
        language: Language.EN,
      },
    },
    {
      domain: "staging-member-ph.ilovevip.com",
      country: DeployCountry.PH,
      env: DeployEnv.Staging,
      isMain: true,
      // Override country configuration
      countryConfig: {
        language: Language.EN,
      },
    },
    // LOCAL DOMAINS
    {
      domain: "localhost",
      country: (process.env.REACT_APP_COUNTRY_ISO as DeployCountry) ?? DeployCountry.TH,
      env: DeployEnv.Local,
      isMain: !process.env.REACT_APP_BRAND,
      brand: process.env.REACT_APP_BRAND,
      mainUrl: process.env.REACT_APP_MAIN_URL || "http://localhost:3000/",
      // Override country configuration
      countryConfig: {
        language: Language.EN,
        currency: process.env.REACT_APP_CURRENCY as CountryCurrency,
      },
    },
  ],
  // Common configurations for all countries
  countries: {
    [DeployCountry.TH]: {
      accountId: process.env.REACT_APP_TH_ACCOUNT_ID || "",
      language: Language.TH,
      phoneCode: CountryPhoneCode.TH,
      envKey: CountryEnvKey.TH,
      isoCode: DeployCountry.TH,
      currency: CountryCurrency.TH,
      postback: {
        campaignId: "cc962385-69d0-401d-bf56-e6e78ec0879d",
        siteUrl: `https://${MAIN_DOMAINS.th}/`,
      },
      contactPlatforms: [
        { type: SOCIAL_MEDIA_TYPE.LINE, url: "https://line.me/R/ti/p/@645ojnqc", title: "Line", primary: true },
      ],
    },
    [DeployCountry.PH]: {
      accountId: process.env.REACT_APP_PH_ACCOUNT_ID || "",
      language: Language.EN,
      phoneCode: CountryPhoneCode.PH,
      envKey: CountryEnvKey.PH,
      isoCode: DeployCountry.PH,
      currency: CountryCurrency.PH,
      postback: {
        campaignId: "462f3166-4d7b-4a60-a0df-8531fb263c28",
        siteUrl: `https://${MAIN_DOMAINS.ph}/`,
      },
      contactPlatforms: [
        {
          type: SOCIAL_MEDIA_TYPE.Messenger,
          url: "https://m.me/200969406423049",
          title: "Messenger",
          primary: true,
        },
        {
          type: SOCIAL_MEDIA_TYPE.Telegram,
          url: "https://t.me/ILV_PH_bot",
          title: "Telegram",
        },
      ],
    },
  },
  server: {
    baseURL: process.env.REACT_APP_BASE_URL ?? "",
  },
};

export default appConfig;
